<template>
    <SimpleTable formclass="Collect/form" endpoint="collects" :aditionalColumns="headers" inlineToggle>
        <template>
            <h1>Coletas</h1>
            <v-divider class="mb-4" />
        </template>
        <template v-slot:extra-column="item">
            <v-chip class="ma-0" outlined color="success" v-if="item.date"> {{ $moment.utc(item.date).format("DD/MM/YYYY") }} </v-chip>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: {
        SimpleTable,
    },
    data() {
        return {
            headers: [
                { text: this.$t("Consolidado"), value: "consolidated", align: "right", sortable: true },
                { text: this.$t("PUDO"), value: "pudo", align: "right", sortable: true },
                { text: this.$t("Seller"), value: "seller", align: "right", sortable: true },
                { text: this.$t("Coletas"), value: "collect", align: "right", sortable: true },
                { text: this.$t("Cliente"), value: "client", align: "right", sortable: true },
                { text: this.$t("Data"), value: "extra-column", align: "right", sortable: true },
            ],
        };
    },
};
</script>